import React, { FC, ReactNode, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';

interface ModalProps {
  show: boolean;
  onClose?: () => void;
  children: ReactNode;
  className?: string;
  zIndex?: number;
}

const Modal: FC<ModalProps> = ({ show, onClose, children, className, zIndex = 50 }) => {
  const [isBrowser, setIsBrowser] = useState(false);

  useEffect(() => {
    setIsBrowser(true);
    return () => onClose?.();
  }, []);
  const modalContent = show ? (
    <div
      style={{
        zIndex,
      }}
      className={classNames(
        className,
        'fixed top-0 z-50 left-0 w-full h-full flex items-center justify-center  bg-opacity-70 bg-black overflow-y-hidden'
      )}
    >
      {children}
    </div>
  ) : (
    <div />
  );

  if (isBrowser) {
    return ReactDOM.createPortal(modalContent, document.getElementById('modal-root')!);
  } else {
    return <div />;
  }
};
export default Modal;
