import React, { PropsWithChildren, useCallback } from 'react';
import classNames from 'classnames';
import { FaChevronDown } from 'react-icons/fa';
import { FieldPath, FieldValues, UseControllerProps } from 'react-hook-form/dist/types';
import { useController } from 'react-hook-form';
import { isSet } from '@/utils/formatChecker';

interface ControllableSelectComponent extends FCWithoutComponent {
  <TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>(
    props: PropsWithChildren<
      UseControllerProps<TFieldValues, TName> & {
        options: Array<Option<TFieldValues[TName]>> | undefined;
        placeholder?: string;
        className?: string;
        valueAsNumber?: boolean;
        id?: string;
        size?: 'base' | 'lg';
        placeholderSelectable?: boolean;
      }
    >
  ): ReturnType<React.FC>;
}

export const FunwooSelect: ControllableSelectComponent = ({
  id,
  options,
  className,
  placeholder,
  valueAsNumber,
  size = 'base',
  placeholderSelectable = false,
  ...props
}) => {
  const {
    fieldState: { error, isDirty },
    field: { value, onChange },
  } = useController(props);

  const onInnerChange = useCallback<React.ChangeEventHandler<HTMLSelectElement>>(
    (event) => {
      if (valueAsNumber) {
        onChange(Number(event.target.value));
      } else {
        onChange(event.target.value);
      }
    },
    [valueAsNumber, onChange]
  );

  return (
    <div
      id={id}
      className={classNames(
        className,
        'relative flex',
        'items-center',
        'border',
        isSet(error) ? 'border-red' : 'border-gray300',
        isDirty && 'shadow-dirty-field'
      )}
    >
      <select
        value={value ?? ''}
        onChange={onInnerChange}
        className={classNames(
          'funwoo-select',
          'px-4',
          {
            'py-2': size === 'base',
            'py-3': size === 'lg',
          },
          'w-full',
          'bg-transparent'
        )}
      >
        <option value={''} disabled={!placeholderSelectable}>
          {placeholder ?? '請選擇'}
        </option>
        {options?.map(({ value, label }) => {
          return (
            <option key={value} value={value}>
              {label}
            </option>
          );
        })}
      </select>
      <FaChevronDown className={'absolute right-4 pointer-events-none'} />
    </div>
  );
};
