import { Box, Button, Flex, Stack, Text } from '@chakra-ui/react';
import React, { PropsWithChildren, useCallback } from 'react';
import ConditionalFragment from '../../../components/common/ConditionalFragment';
import classNames from 'classnames';
import { BsCheck } from 'react-icons/bs';

interface CustomCheckboxProps<Value> {
  options: Array<Option<Value>>;
  value: Array<Value>;
  onChange: (value: Array<Value>) => void;
  fixColumnStyle?: boolean;
  disableDesktopExtraStyle?: boolean;
}

interface CustomCheckboxComponent extends FCWithoutComponent {
  <Value>(props: PropsWithChildren<CustomCheckboxProps<Value>>): ReturnType<React.FC>;
}

const CustomCheckbox: CustomCheckboxComponent = ({
  options,
  fixColumnStyle,
  disableDesktopExtraStyle,
  onChange,
  value = [],
}) => {
  const onButtonPress = useCallback(
    (item: any) => {
      if (value.includes(item)) {
        onChange(value.filter((_value) => _value !== item));
      } else {
        onChange(value.concat(item));
      }
    },
    [value, onChange]
  );

  return (
    <Box
      w={'full'}
      display={'flex'}
      justify={'start'}
      flexDirection={{ md: fixColumnStyle ? undefined : 'row', base: 'column' }}
      flexWrap={{ md: 'wrap' }}
    >
      {options.map(({ label, value: _value }) => {
        return (
          <RadioItem
            disableDesktopExtraStyle={disableDesktopExtraStyle}
            fixColumnStyle={fixColumnStyle}
            onPress={() => onButtonPress(_value)}
            key={label}
            isActive={value.includes(_value)}
            label={label}
          />
        );
      })}
    </Box>
  );
};
const RadioItem: React.FC<{
  isActive: boolean;
  label: string;
  onPress: () => void;
  fixColumnStyle?: boolean;
  disableDesktopExtraStyle?: boolean;
}> = ({ isActive, label, onPress, fixColumnStyle, disableDesktopExtraStyle }) => {
  return (
    <Button
      py={{ md: fixColumnStyle ? undefined : 1, base: 4 }}
      px={{ md: disableDesktopExtraStyle ? undefined : 8, base: 4 }}
      pr={{ md: 4 }}
      height={'auto'}
      onClick={onPress}
      m={0}
      variant={'none'}
      borderRadius={0}
      className={
        disableDesktopExtraStyle
          ? classNames({ 'bg-gray100 md:bg-transparent': isActive })
          : classNames('hover:bg-gray100', { 'bg-gray100': isActive })
      }
    >
      <Flex
        w={'full'}
        align={'center'}
        flexDirection={{ md: 'row', base: 'row-reverse' }}
        justifyContent={{ base: 'space-between', md: 'flex-start' }}
      >
        <Stack
          border={`2px solid black`}
          w={5}
          h={5}
          borderRadius={4}
          alignItems={'center'}
          justifyContent={'center'}
          className={'flex-shrink-0'}
        >
          <ConditionalFragment condition={isActive}>
            <span className={'relative flex items-center justify-center w-full h-full bg-black text-white'}>
              <BsCheck size={20} className={'absolute'} />
            </span>
          </ConditionalFragment>
        </Stack>
        <Text
          fontSize={16}
          lineHeight={'24px'}
          letterSpacing={0.5}
          fontWeight={'400'}
          textColor={'black'}
          ml={{ md: 2 }}
        >
          {label}
        </Text>
      </Flex>
    </Button>
  );
};
export default CustomCheckbox;
