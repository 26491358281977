import React, { PropsWithChildren } from 'react';
import { FieldErrors, FieldName } from 'react-hook-form';

import classNames from 'classnames';

import { ErrorMessage, FieldValuesFromFieldErrors } from '@hookform/error-message';

export const FieldError: React.FC<{ message: string }> = ({ message }) => (
  <p className={classNames('text-sm text-[#e53e3e]')}>{message}</p>
);

interface HookFormErrorComponent extends FCWithoutComponent {
  <FormValues>(
    props: PropsWithChildren<{
      errors: FieldErrors<FormValues>;
      name: FieldName<FieldValuesFromFieldErrors<FieldErrors<FormValues>>>;
    }>,
    context: any
  ): React.ReactElement | null;
}

export const HookFormError: HookFormErrorComponent = ({ errors, name }) => {
  return <ErrorMessage errors={errors} name={name} render={FieldError} />;
};
